import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AppComplete } from '../../pages/Misc/AppComplete';
import Onboarding from '../../pages/Onboarding/Onboarding';
import FindLiveInventory from '../../pages/User Manual/FindLiveInventory';
import UserManualPreview from '../../pages/User Manual/Preview';
import UserManual from '../../pages/User Manual/UserManual';
import AuthGuard from './AuthGuard';
import UserInfoGuard from './UserInfoGuard';

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/user-manual" Component={UserManual} />
                <Route path="/user-manual/preview" Component={UserManualPreview} />
                <Route
                    path="/find-live-inventory"
                    Component={() => (
                        <AuthGuard>
                            <FindLiveInventory />
                        </AuthGuard>
                    )}
                />
                <Route
                    path="/onboarding"
                    Component={() => (
                        <UserInfoGuard checkEULA={true}>
                            <Onboarding />
                        </UserInfoGuard>
                    )}
                />
                <Route
                    path="/app-complete"
                    Component={AppComplete}
                />
            </Routes>
        </Router>
    );
}

export default AppRouter;