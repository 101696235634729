import { Box, Typography } from '@mui/material'
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { KeyboardEventHandler, useState } from 'react'
import { auth } from '../../firebaseConfig'
import styles from '../../styles/Auth.module.css'
import Button from '../Button'
import Loading from '../Loading'

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier;
        confirmationResult: ConfirmationResult;
    }
}

export default function Auth() {
    const [loading, setLoading] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [confirm, setConfirm] = useState('')
    const [number, setNumber] = useState<string | null>(null)

    const title = isConfirm ? 'Confirmation Code' : 'Enter your phone number'
    const message = isConfirm ? 'Enter the code from your text message' : 'A text message will be sent to this device for verification'

    function handleInput(text: string) {
        if (isConfirm) {
            setConfirm(text)
            return
        }

        if (text == null || text.length < 10) {
            setNumber(text)
            return
        }

        let formattedText = ''

        if (text.length === 10) {
            formattedText = text.slice(0, 3) + '-' + text.slice(3, 6) + '-' + text.slice(6)
        } else if (text.includes('-')) {
            formattedText = text.slice(0, 3) + text.slice(4, 7) + text.slice(8)
        } else {
            formattedText = text
        }

        setNumber(formattedText)
    }

    const handleDone = async () => {
        try {
            setLoading(true)

            if (!isConfirm) {
                await handleLogin()
            } else {
                await handleVerification()
            }
        } catch (e: any) {
            console.log(e)
            window.alert(`Error: ${e.message}\n\nPlease contact support at info@pv-rental.com`)
        } finally {
            setLoading(false)
        }
    }

    const handleLogin = async () => {
        if (!number) return

        generateRecaptcha()
        const appVerifier = window.recaptchaVerifier
        const formattedNumber = '+1' + number.slice(0, 3) + number.slice(4, 7) + number.slice(8)

        const confirmationResult = await signInWithPhoneNumber(auth, formattedNumber, appVerifier)
        window.confirmationResult = confirmationResult
        setIsConfirm(true)
    }

    const generateRecaptcha = () => {
        if (window.recaptchaVerifier) {
            return
        }

        window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-container',
            { 'size': 'invisible' }
        )
    }

    const handleVerification = async () => {
        if (!confirm) return
        await window.confirmationResult.confirm(confirm)
    }

    const renderContent = () => {
        if (loading) {
            return <Loading />
        }

        return (
            <Box
                sx={{
                    width: '450px',
                    height: '350px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '10px',
                    borderRadius: '8px',
                    marginTop: '-10%',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <Typography
                        variant='h5'
                        sx={{ fontSize: 25, color: '#363636', mt: '20px', marginBottom: '20px' }}
                    >
                        {title}
                    </Typography>

                    <Input
                        value={isConfirm ? confirm : number}
                        placeholder={isConfirm ? 'Code' : 'Phone Number'}
                        onChange={handleInput}
                    />

                    <Typography sx={{ fontSize: 15, color: 'gray', mt: '15px' }}>
                        {message}
                    </Typography>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: 30
                    }}
                >
                    <Button
                        title={isConfirm ? 'Verify' : 'Done'}
                        onClick={handleDone}
                        disabled={!number}
                    />
                </div>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                flex: 1,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {renderContent()}
            <div id={'recaptcha-container'}></div>
        </Box>
    )
}

type InputProps = {
    value: string | null,
    placeholder: string,
    onChange: (text: string) => void
}

function Input(props: InputProps) {
    const { value, placeholder, onChange } = props
    const onKeyDown: KeyboardEventHandler = (e) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    return (
        <form onKeyDown={onKeyDown}>
            <input
                value={value ?? ''}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                id={styles.codeInput}
            />
        </form>
    )
}