import { errorAlert } from "../../../utils/GlobalUtils";
import Content from "../Content";

export default function Complete() {
    const title = 'Complete'
    const paragraphs = [
        'Welcome aboard! You are officially onboarded with PVR, and you are ready to start streamlining your rental business. To get up and running, read through our User Manual to learn about the features of the app and what\'s next.',
        'We strongly recommend bookmarking the User Manual page as it will be your primary resource for questions and is updated regularly with the latest features and FAQs.'
    ]

    const handleDone = async () => {
        try {
            window.location.href = `/user-manual`
        } catch (e: any) {
            errorAlert(e)
        }
    }

    return (
        <Content
            title={title}
            paragraphs={paragraphs}
            doneInfo={{
                title: 'Tutorial',
                enabled: true,
                onClick: handleDone
            }}
        />
    )
}