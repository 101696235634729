import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30vh',
        height: '100vh',
        width: '100vw'
    },

    message: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'gray'
    }
}))

export function AppComplete() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CloudDoneIcon style={{ fontSize: 100, color: '#fa4903' }} />
            <h1 className={classes.message}>
                You may now return to the app
            </h1>
        </div>
    );
}