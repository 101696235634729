import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { isDev } from './utils/GlobalUtils'

const firebaseConfig = {
	apiKey: 'AIzaSyAaUWu16csGGUhSZw2rfETaEztzrWxMWa0',
	authDomain: 'pv-rental.firebaseapp.com',
	projectId: 'pv-rental',
	storageBucket: 'pv-rental.appspot.com',
	messagingSenderId: '841780618206',
	appId: '1:841780618206:web:1b391087840a28ac01334f',
	measurementId: 'G-1YHFLDW9MT'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)

if (isDev()) {
	const functions = getFunctions(app)

	connectFirestoreEmulator(db, 'localhost', 8079)
	connectAuthEmulator(auth, 'http://localhost:9098')
	connectFunctionsEmulator(functions, 'localhost', 5001)
	setAnalyticsCollectionEnabled(analytics, false)
} else {
	setAnalyticsCollectionEnabled(analytics, true)
}

export { analytics, auth, db, firebaseConfig }
