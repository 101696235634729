import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";

type AccordionType = {
    title: string;
    content: string;
}

type AccordionListProps = {
    title: string;
    accordions: AccordionType[];
}

export default function AccordionList(props: AccordionListProps) {
    const { title, accordions } = props;

    return (
        <Box sx={{ marginTop: '2rem' }}>
            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>{title}</Typography>
            {
                accordions.map((accordion, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body1" sx={{ fontWeight: '500' }}>{accordion.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{accordion.content}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Box >
    )
}