import { useState } from "react";
import { auth } from "../../../firebaseConfig";
import { errorAlert, fetchRequest } from "../../../utils/GlobalUtils";
import Content from "../Content";

type BankingProps = {
    status: 'start' | 'pending' | 'failed'
}

export default function Banking(props: BankingProps) {
    const { status } = props
    const [loading, setLoading] = useState(false)

    const startParagraphs = [
        'In order to collect payment from your renters, PVR needs to know where to deposit the funds. To do so, PVR partners with Stripe to ensure your funds are processed safely and that your banking information is accurate. Pressing the button below will take you through Stripe\'s onboarding process to enable payment collection. Before starting this process, make sure to have your business and personal information on hand.',
        'Note: if you already have a Stripe account, we suggest completing this process with a new email to create a PVR specific account.'
    ]

    const pendingParagraphs = [
        'We have received your banking information and it is currently being processed. Refresh this page in a few minutes to check on the status.',
        'Note: you will not be able to use the app until your information has been approved.'
    ]

    const failedParagraphs = [
        'It looks like some of your banking information was not processed correctly. Please press the button below to fix any incorrect information or add additional information requested by Stripe.',
        'Note: you will not be able to use the app until your information has been approved.'
    ]

    let title = ''
    let paragraphs: string[] = []
    switch (status) {
        case 'start':
            title = 'Banking Information'
            paragraphs = startParagraphs
            break
        case 'pending':
            title = 'Verification Pending'
            paragraphs = pendingParagraphs
            break
        case 'failed':
            title = 'Verification Failed'
            paragraphs = failedParagraphs
            break
        default:
            errorAlert(new Error('undefined banking type'))
            break
    }

    const handleDone = async () => {
        try {
            setLoading(true)
            const res = await fetchRequest('getConnectAccountLink', {
                uid: auth.currentUser?.uid
            })

            if (res.url) {
                window.location.href = res.url
            }
        } catch (e: any) {
            errorAlert(e)
        }
    }

    return (
        <Content
            title={title}
            paragraphs={paragraphs}
            loading={loading}
            doneInfo={{
                title: 'Begin',
                enabled: true,
                hidden: status === 'pending',
                onClick: handleDone
            }}
        />
    )
}