import * as contentful from 'contentful'
import { memoize } from 'lodash'

export type ContentfulEntryType = contentful.Entry<contentful.EntrySkeletonType, undefined, string>

const getPageDataHandler = async (id: string, usePreview = false): Promise<ContentfulEntryType> => {
    const client = getClient()
    return await client.getEntry(id)
}

const getPageData = memoize(getPageDataHandler)

const getClient = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const usePreview = urlParams.get('preview') === 'true'
    const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
    const previewAccessToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN;
    const token = usePreview ? previewAccessToken : accessToken

    if (!token) {
        throw new Error("Missing Contentful access token")
    }

    const client = contentful.createClient({
        space: '76ezlwj1loki',
        environment: 'master', // defaults to 'master' if not set
        accessToken: token,
        host: usePreview ? "preview.contentful.com" : "cdn.contentful.com"
    })

    return client
}

export {
    getClient,
    getPageData
}

