import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const drawerWidth = 240;

export type ScreenType = {
    name: string;
    component: React.ComponentType;
};

export type SectionType = {
    title: string;
    screens: ScreenType[];
};

type Props = {
    sections: SectionType[];
}

export default function DrawerNav(props: Props) {
    const { sections } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [selectedScreen, setSelectedScreen] = React.useState(sections[0].screens[0].name);
    const foundScreen = sections.flatMap(section => section.screens).find(screen => screen.name === selectedScreen);
    const SelectedScreenComponent = foundScreen ? foundScreen.component : null;

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const screenFromUrl = sections.flatMap(section => section.screens).find(screen => screen.name.toLowerCase() === urlParams.get('screen')?.replace(/-/g, ' '));
        if (screenFromUrl) {
            setSelectedScreen(screenFromUrl.name);
        }
    }, [sections]);

    const drawer = (
        <div style={{ paddingBottom: '5vh' }}>
            <Toolbar />
            <Divider />
            {sections.map((section) => (
                <React.Fragment key={section.title}>
                    <Divider />
                    <Typography
                        noWrap
                        variant="h6"
                        component="div"
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            paddingLeft: '1rem',
                            paddingTop: '1rem',
                        }}
                    >
                        {section.title}
                    </Typography>
                    <List>
                        {section.screens.map((screen) => (
                            <ListItem
                                disablePadding
                                key={screen.name}
                                sx={{
                                    backgroundColor: selectedScreen === screen.name ? '#f5f5f5' : 'white',
                                }}
                            >
                                <ListItemButton onClick={() => setSelectedScreen(screen.name)}>
                                    <ListItemText
                                        primary={screen.name}
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            paddingLeft: '1rem',
                                            margin: 0,
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            ))}
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: 'white',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        noWrap
                        variant="h6"
                        component="div"
                        sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 'bold',
                            color: 'black',
                        }}>
                        PVR User Manual
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {SelectedScreenComponent && <SelectedScreenComponent />}
            </Box>
        </Box>
    );
}
