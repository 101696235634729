import { useState } from "react";
import { auth } from "../../../firebaseConfig";
import { errorAlert, fetchRequest } from "../../../utils/GlobalUtils";
import Content from "../Content";

export default function Subscription() {
    const [loading, setLoading] = useState(false)
    const title = 'Subscription'
    const paragraphs = [
        'Welcome to PVR! The first step in the onboarding process is to create your subscription. This is a recurring payment which allows you to create a club and grants you access to the coach features of the app.',
        'Please note, after creating your subscription, make sure to sign in using the same credentials that you used to create your subscription. This will allow you to access the coach features of the app.'
    ]

    const handleDone = async () => {
        try {
            setLoading(true)
            const res = await fetchRequest('pvrSubCreation', {
                uid: auth.currentUser?.uid
            })

            if (res.url) {
                console.log(res.url)
                window.location.href = res.url
            }
        } catch (e: any) {
            errorAlert(e)
        }
    }

    return (
        <Content
            title={title}
            paragraphs={paragraphs}
            loading={loading}
            doneInfo={{
                title: 'Begin',
                enabled: true,
                onClick: handleDone
            }}
        />
    )
}