import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import TagPricing from "../../../components/TagPriceCalculator";
import { auth, db } from "../../../firebaseConfig";
import { errorAlert, fetchRequest } from "../../../utils/GlobalUtils";
import Content from "../Content";

export default function OrderTags() {
    const [loading, setLoading] = useState(false)
    const [tagPrices, setTagPrices] = useState([])
    const [quantity, setQuantity] = useState<number | undefined>()

    const title = 'Order Tags'
    const paragraphs = [
        'Congratulations! You are now the proud owner of a PVR Club. The next step in the onboarding process is to order your PVR tags. You will use these tags to inventory your rental items.',
        'Use the pricing calculator to select your order quantity and preview our pricing options.'
    ]

    useEffect(() => {
        const getTagPrices = async () => {
            const snap = await getDoc(doc(db, 'admin', 'products'))
            const data = snap.data()
            const tags = data?.tags || []

            setTagPrices(tags.sort((a: any, b: any) => b.price - a.price))
        }

        setLoading(true)
        getTagPrices()
            .catch(errorAlert)
            .finally(() => setLoading(false))
    }, [])

    const handleDone = async () => {
        try {
            setLoading(true)
            const res = await fetchRequest('orderTags', {
                quantity,
                uid: auth.currentUser?.uid,
                isOnboarding: true
            })

            if (res.url) {
                console.log(res.url)
                window.location.href = res.url
            }
        } catch (e: any) {
            errorAlert(e)
        }
    }

    return (
        <Content
            title={title}
            paragraphs={paragraphs}
            loading={loading}
            doneInfo={{
                title: 'Begin',
                enabled: !!quantity && quantity > 0,
                onClick: handleDone
            }}
        >
            <TagPricing prices={tagPrices} setQuantity={setQuantity} />
        </Content>
    )
}