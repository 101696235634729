import { ButtonProps, Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(MuiButton)({
    background: 'white',
    border: '1.5px solid #fa4903',
    fontFamily: 'Montserrat, sans-serif',
    color: '#fa4903',
    fontWeight: 'bold',
    padding: '0.3rem 1.5rem',
    marginRight: '1rem',
    '&:hover': {
        background: '#fa4903',
        color: 'white',
    },
    '&:disabled': {
        borderColor: 'gray',
        color: 'gray',
    },
});

export default function Button(props: ButtonProps) {
    return <StyledButton {...props}>{props.title}</StyledButton>;
}