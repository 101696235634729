// @ts-ignore
import { Spinner } from "loading-animations-react"
import styles from "../styles/Loading.module.css"

export default function Loading() {
    return (
        <div id={styles.container}>
            <Spinner
                color1='#fa5f22'
                color2='#ff7f4d'
                text=''
                id={styles.loading}
            />
        </div>
    )
}