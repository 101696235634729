import { useEffect, useState } from 'react'
import { auth } from '../../firebaseConfig'
import { isPreview } from '../../utils/GlobalUtils'
import Auth from './Auth'
import { EmailAuth } from './EmailAuth'

type AuthGuardProps = {
    children: React.ReactNode
    requireUserInfo?: boolean
}

export default function AuthGuard(props: AuthGuardProps) {
    const { children } = props
    const user = auth.currentUser
    const [showAuth, setShowAuth] = useState(!user)

    useEffect(() => {
        return auth.onAuthStateChanged((res) => {
            setShowAuth(!res)
        })
    }, [user])

    if (showAuth) {
        if (isPreview()) {
            return <EmailAuth />
        }

        return <Auth />
    }

    return children
}