import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import DrawerNav, { SectionType } from "../../components/navigation/DrawerNav";
import FeaturePage from './FeaturePage';
import { getClient } from './userManualHelpers';

export default function UserManual() {
    const [loading, setLoading] = useState(true)
    const [sections, setSections] = useState<SectionType[]>([])

    const getScreens = async () => {
        const client = getClient()
        const res = await client.getEntries({
            content_type: 'featurePage',
            select: ['fields.title', 'fields.section', 'fields.order']
        })

        const sectionTitles = ['Getting Started', 'Coach Features', 'Athlete Features']
        const sections = sectionTitles.map((title: string) => {
            const sectionScreens = res.items.filter((item: any) => item.fields.section === title)
            const sectionScreensSorted = sectionScreens.sort((a: any, b: any) => a.fields.order - b.fields.order)
            const screens = sectionScreensSorted.map((item: any) => {
                const title = item.fields.title
                const entryID = item.sys.id
                const Component = () => <FeaturePage id={entryID} />

                return { name: title, component: Component }
            })

            return { title, screens }
        })

        setSections(sections)
    }

    useEffect(() => {
        getScreens()
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <DrawerNav sections={sections} />
    )
}