import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";

type BulletedListProps = {
    title?: string;
    items: (string | string[])[];
    isNumbered?: boolean;
}

export function BulletedList(props: BulletedListProps) {
    const { title, items, isNumbered } = props;

    return (
        <div>
            {title && (
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                        marginTop: '2rem',
                        fontFamily: 'Montserrat, sans-serif',
                    }}
                >
                    {title}
                </Typography>
            )}

            <List>
                {items.map((item, index) => (
                    <ListItem key={index} alignItems="flex-start">
                        {isNumbered ? (
                            <Typography sx={{ marginRight: '1vw' }}>{index + 1}.</Typography>
                        ) : (
                            <ListItemIcon sx={{ marginRight: '-1.2vw' }}>
                                <FiberManualRecordIcon sx={{ color: 'black', fontSize: 12 }} />
                            </ListItemIcon>
                        )}
                        {typeof item === 'string' ? (
                            <Typography>{item}</Typography>
                        ) : (
                            item.map((subItem, subIndex) => (
                                <Typography key={subIndex}>{subItem}</Typography>
                            ))
                        )}
                    </ListItem>
                ))}
            </List>
        </div>
    );
}