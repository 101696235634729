import { DocumentData, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { auth, db } from '../../firebaseConfig'
import styles from '../../styles/UserInfo.module.css'
import { errorAlert, isPreview } from '../../utils/GlobalUtils'
import Button from '../Button'
import Loading from '../Loading'
import Auth from './Auth'
import { EmailAuth } from './EmailAuth'

type UserInfoGuardProps = {
    children: React.ReactNode
    checkEULA?: boolean
}

export default function UserInfoGuard(props: UserInfoGuardProps) {
    const { children, checkEULA = false } = props
    const user = auth.currentUser
    const [loading, setLoading] = useState(true)
    const [showUI, setShowUI] = useState(false)
    const [showAuth, setShowAuth] = useState(!user)
    const [showEULA, setShowEULA] = useState(false)

    useEffect(() => {
        const init = async () => {
            if (!user?.uid) return
            const snap = await getDoc(doc(db, `users/${user.uid}`))
            if (!snap.exists()) {
                setShowEULA(checkEULA)
                setShowUI(true)
            }
        }

        setLoading(true)
        init()
            .catch(errorAlert)

        return auth.onAuthStateChanged((res) => {
            setShowAuth(!res)
            setLoading(false)
        })
    }, [user, checkEULA])

    if (loading) {
        return <Loading />
    }

    if (showAuth) {
        if (isPreview()) {
            return <EmailAuth />
        }

        return <Auth />
    }

    if (showUI) {
        return (
            <UserInfo
                setShowUI={setShowUI}
                setLoading={setLoading}
                showEULA={showEULA}
            />
        )
    }

    return children
}

type UserInfoProps = {
    setShowUI: (showUI: boolean) => void
    setLoading: (loading: boolean) => void
    showEULA?: boolean
}

function UserInfo(props: UserInfoProps) {
    const { setShowUI, setLoading, showEULA } = props
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [agreed, setAgreed] = useState(false)

    const isValid = firstName !== '' && lastName !== '' && email.includes('@') && (agreed || !showEULA)

    const handleDone = async () => {
        try {
            setLoading(true)
            if (!auth.currentUser) return
            const uid = auth.currentUser.uid
            let data: DocumentData = {
                firstName,
                lastName,
                name: `${firstName} ${lastName}`,
                email,
                uid
            }

            if (showEULA) {
                data = {
                    ...data,
                    eula_agreed: serverTimestamp()
                }
            }

            await setDoc(doc(db, `users/${uid}`), data)
        } catch (e: any) {
            errorAlert(e)
        } finally {
            setLoading(false)
            setShowUI(false)
        }
    }

    return (
        <div id={styles.container}>
            <div id={styles.wrapper}>
                <h2 style={{ marginTop: 0 }}>Enter Your Information</h2>
                <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    className={styles.input}
                />
                <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    className={styles.input}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={styles.input}
                />
                {showEULA && (
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id={styles.termsCheckbox}
                            name="termsCheckbox"
                            value="agreed"
                            onChange={e => setAgreed(e.target.checked)}
                        />
                        <label
                            htmlFor="termsCheckbox"
                            style={{ color: 'gray', marginLeft: 10 }}
                        >
                            I agree to the terms and conditions of the <a id={styles.eula} href='https://pv-rental.web.app/EULA.pdf'>license</a>
                        </label>
                    </div>
                )}

                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: 30,
                    right: 30
                }}>
                    <Button
                        title="Done"
                        disabled={!isValid}
                        onClick={handleDone}
                    />
                </div>
            </div>
        </div>
    )
}