import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from "react";
import EmptyPage from '../../components/EmptyPage';
import Loading from "../../components/Loading";
import { auth, db } from '../../firebaseConfig';

export default function FindLiveInventory() {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const init = async () => {
            const uid = auth.currentUser?.uid
            const snap = await getDoc(doc(db, `users/${uid}`))
            const data = snap.data()

            console.log(data)

            if (data?.club) {
                const url = `https://pv-rental.web.app/live-inventory/${data.club}`
                window.location.href = url
            } else {
                setLoading(false)
            }
        }

        init()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <EmptyPage
            message='It appears that you are not associated with a club. If you are interested in joing PVR, please reach out to our team at info@pv-rental.com'
        />
    )
}