import { Box } from "@mui/material";
import Button from "../../components/Button";

type LinkButtonsProps = {
    links: { title: string, url: string }[];
}

export default function LinkButtons(props: LinkButtonsProps) {
    const { links } = props;

    const handleLinkClick = (url: string) => {
        window.open(url, '_blank') // open link in new
    }

    return (
        <Box sx={{ marginTop: '1.5rem' }}>
            {
                links.map((link, index) => (
                    <Button
                        key={index}
                        title={link.title}
                        onClick={() => handleLinkClick(link.url)}
                    />
                ))
            }
        </Box>
    )
}