import { Box, Typography } from '@mui/material'
import { ChangeEventHandler, useState } from 'react'
import styles from '../styles/TagPricing.module.css'

type PriceType = {
    limit: number | 'inf'
    price: number
}

type TagPricingProps = {
    prices: PriceType[]
    setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>
}

export default function TagPricing(props: TagPricingProps) {
    const { prices, setQuantity } = props
    const [price, setPrice] = useState(0)

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const val = parseInt(e.target.value)
        if (!val) {
            setPrice(0)
        } else {
            setPrice(getTieredPrice(val))
        }

        setQuantity(val)
    }

    const getTieredPrice = (quantity: number) => {
        let res = 0.0
        let prevLimit = 0.0

        for (let i = 0; i < prices.length; i++) {
            const p = prices[i]
            if (p.limit === 'inf') {
                res += (quantity - prevLimit) * p.price
                return res
            }

            if (quantity <= p.limit) {
                res += (quantity - prevLimit) * p.price
                return res
            } else {
                res += (p.limit - prevLimit) * p.price
            }

            prevLimit = p.limit
        }

        return res
    }

    return (
        <Box
            id={styles.container}
            sx={{
                width: {
                    xs: '100%',
                    lg: '80vw'
                },
                marginBottom: {
                    xs: '10vh',
                    lg: '0'
                },
                padding: {
                    xs: '30px 30px',
                    lg: '25px 30px'
                }
            }}
        >
            <Typography variant='h5' sx={{ marginBottom: 2 }}>Total: ${price.toFixed(2)}</Typography>
            <form>
                <input
                    id={styles.quantity}
                    placeholder="Quantity"
                    onChange={handleChange}
                />
            </form>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    marginTop: '1vh'
                }}
            >
                {
                    prices.map((price, idx) => {
                        if (idx === 0) {
                            return (
                                <h2>{`First ${price.limit}: $${price.price.toFixed(2)}`}</h2>
                            )
                        } else if (idx === prices.length - 1) {
                            return (
                                <h2>{`Remaining: $${price.price.toFixed(2)}`}</h2>
                            )
                        } else {
                            const currentLimit = price.limit
                            const prevLimit = prices[idx - 1].limit
                            if (currentLimit === 'inf' || prevLimit === 'inf') {
                                return null
                            }

                            const diff = currentLimit - prevLimit
                            return (
                                <h2>{`Next ${diff}: $${price.price.toFixed(2)}`}</h2>
                            )
                        }
                    })
                }
            </Box>
        </Box >
    )
}