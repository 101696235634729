import { Box, Typography } from "@mui/material";

type ProgressProps = {
    title: string;
    steps: string[];
    activeStep: number;
}

export default function Progress(props: ProgressProps) {
    const { title, steps, activeStep } = props;

    return (
        <Box
            sx={{
                backgroundColor: '#fa4903',
                flex: '1 1 33%',
                boxSizing: 'border-box',
                padding: '20vh 5vw',
                display: { xs: 'none', sm: 'inline' },
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    color: 'white', fontSize: {
                        sm: '1.5rem',
                        md: '2rem',
                    }
                }}
            >
                {title}
            </Typography>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginTop: '3vh',
                }}
            >
                {steps.map((step, index) => (
                    <Step
                        key={step}
                        title={step}
                        stepNumber={index + 1}
                        isActive={index === activeStep}
                    />
                ))}
            </Box>
        </Box>
    )
}

type StepProps = {
    title: string;
    stepNumber: number;
    isActive: boolean;
}

function Step(props: StepProps) {
    const { title, stepNumber, isActive } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: '10px',
                marginBottom: '2vh'
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '35px',
                aspectRatio: '1',
                borderRadius: '50%',
                border: '2.5px solid white',
                color: isActive ? '#fa4903' : 'white',
                backgroundColor: isActive ? 'white' : 'transparent',
                marginRight: '1vw',
            }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>{stepNumber}</Typography>
            </div>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: '400',
                    color: 'white',
                    fontSize: {
                        sm: '1rem',
                        md: '1.25rem',
                    }
                }}
            >
                {title}
            </Typography>
        </Box>
    )
}