import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import AppRouter from './components/navigation/Router';

export default function App() {
	const theme = createTheme({
		typography: {
			fontFamily: 'Montserrat, sans-serif',
			h3: {
				fontWeight: 'bold',
			},
			h4: {
				fontWeight: 'bold',
			},
			h5: {
				fontWeight: 'bold',
			}
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<AppRouter />
		</ThemeProvider>
	);
}
